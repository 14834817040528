import React, { useEffect, useState, useRef, useCallback } from "react";
import "../Localremovalists/Localremovalists.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import "../Home/Home.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import video from "../../Assests/Skilled-Movers-Video.mp4";
import movers from "../../Assests/movers.jpg";
import truck from "../../Assests/delivery-truck.webp";
import booking from "../../Assests/booking.webp";
import personalized from "../../Assests/personalized.webp";
import life from "../../Assests/life.webp";
import logistic from "../../Assests/logistic.webp";
import insurance from "../../Assests/insurance.webp";
import SitBack from "../../Assests/SitBack.jpg";
import removalists from "../../Assests/removalists.jpg";
import relocations from "../../Assests/relocations.jpg";
import choosing from "../../Assests/choosing.jpg";
import preparing from "../../Assests/preparing.jpg";
import Claytoninfo from "./Claytoninfo";
import { Helmet } from "react-helmet-async";

const googleMapsApiKey = "AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw";

const Clayton = () => {
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
      script.async = true;
      // script.onload = () => {
      //   if (window.google && window.google.maps) {
      //     new window.google.maps.places.Autocomplete(pickupRef.current, {
      //       types: ["geocode"],
      //     });
      //     new window.google.maps.places.Autocomplete(dropoffRef.current, {
      //       types: ["geocode"],
      //     });
      //   }
      // };

      script.onload = () => {
        if (window.google && window.google.maps) {
          const options = {
            types: ["geocode"],
            componentRestrictions: { country: "AU" },
          };

          new window.google.maps.places.Autocomplete(
            pickupRef.current,
            options
          );
          new window.google.maps.places.Autocomplete(
            dropoffRef.current,
            options
          );
        }
      };
      script.onerror = () => {
        console.error("Error loading Google Maps script");
      };
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();
  }, []);

  const handleQuoteRequest = useCallback(async () => {
    const fromLocation = pickupRef.current?.value || "";
    const toLocation = dropoffRef.current?.value || "";

    // Validation check
    if (!fromLocation || !toLocation) {
      setErrorMessage("Please provide the locations");
      return;
    }

    // Check if both locations are in Australia
    const isValidAUAddress = (location) => {
      return location.includes("Australia") || location.includes("AU");
    };

    if (!isValidAUAddress(fromLocation) || !isValidAUAddress(toLocation)) {
      setErrorMessage("Please provide valid Australian locations");
      return;
    }

    try {
      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/new-enquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fromlocation: fromLocation,
            tolocation: toLocation,
          }),
        }
      );

      const data = await response.json();
      console.log("Quote data:", data);

      if (data.success) {
        // Save data to local storage
        localStorage.setItem("enquiry_id", data.enquiry_id);
        localStorage.setItem("from_location", fromLocation);
        localStorage.setItem("to_location", toLocation);

        console.log(
          "Enquiry ID, from location, and to location saved to local storage"
        );
        navigate("/accessproperties");
        window.location.reload();
      } else {
        console.error("Failed to save enquiry:", data.message);
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []);

  return (
    <>
      {/* Add meta title and description */}
      <Helmet>
        <title>Removalists in Clayton | Skilled Movers </title>
        <meta
          name="description"
          content="Clayton’s trusted removalists specializing in quick, reliable, and stress-free moving services. Contact Skilled Movers today to get a free quote for your next move!"
        />
      </Helmet>
      <meta name="robots" content="index, follow" />
      {/* end of meta title */}

      <div className="banner__image mobile__banner__image">
        <div className="banner skilled__content">
          <div className="text-section mobile__text">
            <h1 className="melbourn__mobile__heading">
              Your Trusted Melbourne Removalists <br />
            </h1>
            <h2 className="melbourn__mobile__second_heading_">
              Compare, Choose, Move
            </h2>
            {/* <p className="m-1">
              One Of Australia’s Top Rated Moving & Cleaning Company.
            </p> */}
            {/* <p className="m-1 rating-text">
              4.9 <span className="gold-stars">★★★★★</span>
            </p>
            <p className="m-1">
              "Earned 248 Google Review from Satisfied Customers"
            </p> */}
          </div>
          <div className="form-section mobile__location w-50">
            <div className="moving-form">
              <h1 class="small-heading fs-4 font-weight-bold">
                Start your better move.
              </h1>

              <div className="form-group-container ">
                <div
                  className="form-group mb-0"
                  style={{ justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  <input
                    style={{
                      width: "calc(100% - 40px)", // Adjust width to fit the container
                      padding: "15px 15px 15px 40px", // Ensure padding accounts for the icon
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxSizing: "border-box", // Include padding and border in element's total width and height
                      fontSize: "1rem",
                    }}
                    type="text"
                    placeholder="Picking up from"
                    ref={pickupRef}
                    onChange={() => setErrorMessage("")}
                  />
                </div>
                <div
                  className="form-group mb-0"
                  style={{ justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  <input
                    style={{
                      width: "calc(100% - 40px)", // Adjust width to fit the container
                      padding: "15px 15px 15px 40px", // Ensure padding accounts for the icon
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxSizing: "border-box", // Include padding and border in element's total width and height
                      fontSize: "1rem",
                    }}
                    type="text"
                    placeholder="Dropping off at"
                    ref={dropoffRef}
                    onChange={() => setErrorMessage("")}
                  />
                </div>
                {/* Error message display */}
                {errorMessage && (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
                <Link
                  to="/accessproperties"
                  className="quote-button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleQuoteRequest();
                  }}
                >
                  Get an Instant Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ========================================================= */}
      <div className="container my-5">
        <h2 className="text-center mb-3 mb-lg-5">Removalists in Clayton</h2>
        <div className="row">
          <p style={{ textAlign: "justify" }}>
            Moving can be one of life’s most exciting yet stressful experiences.
            Whether you’re relocating across town or making a big move to the
            vibrant city of Clayton, there’s a lot to consider. With the right
            help, though, this transition can become seamless and
            straightforward. That’s where professional removalists come into
            play. They not only manage heavy lifting but also take care of
            logistics that often overwhelm even the best planners.
          </p>
          <p style={{ textAlign: "justify" }}>
            If you're looking for local removalists traveling to or from
            Clayton, it's essential to understand what sets Skilled Movers apart
            in this bustling metropolis. From packing your cherished belongings
            with care to ensuring they're safely transported, Skilled Movers
            have mastered every aspect of moving house in Clayton. Let’s dive
            into some essential insights about hiring Skilled Movers as your
            removalists in Clayton that will transform your next move into an
            effortless journey!
          </p>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          {/* Left Column - Video */}
          <div className="col-md-12 col-sm-12 col-lg-4 ">
            <video
              className="custom-video shadow rounded "
              autoPlay
              loop
              muted
              controls
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          {/* Right Column - Text */}
          <div className="col-md-12 col-sm-12 col-lg-8 d-flex flex-column justify-content-center">
            <h2 className="text-center mt-4 mt-lg-0">
              Why Choose Skilled Movers as a Removalist in Clayton?
            </h2>
            <p className="information__justify">
              Choosing a Skilled Movers as your removalist in Clayton can be
              daunting, but it doesn’t have to be. When you book with us, you'll
              gain access to experienced professionals who understand the ins
              and outs of this vibrant city.
            </p>
            <p className="information__justify">
              Our service includes everything from packing and loading to
              transportation and unpacking at your new home. You won’t have to
              lift a finger; we handle it all efficiently.
            </p>
            <p className="information__justify">
              What sets us apart is our local knowledge. We’ve mastered moving
              houses in Clayton by navigating busy streets, understanding peak
              hours, and identifying the best routes for quick delivery.
            </p>
            <p className="information__justify">
              Safety is also our priority. We are equipped with the right tools
              and techniques to protect your belongings and ensure no one gets
              injured during the move.
            </p>
            <p className="information__justify">
              We also offer tailored packages that cater specifically to your
              needs—whether you're moving to a small apartment or a large family
              home. With expert guidance throughout the process, your move will
              feel less overwhelming and more like an exciting new chapter
              waiting to unfold.
            </p>
          </div>
        </div>

        <div className="row d-flex justify-content-center align-items-center my-0 mt-lg-5">
          <div className="col-md-12 col-sm-12 col-lg-6">
            <h2 className="text-center">
              Importance of hiring professional removalists in Clayton!
            </h2>
            <p className="information__justify">
              Moving can be overwhelming, but with Skilled Movers, you don't
              have to stress about the logistics, packing, or heavy lifting.
              Skilled Movers are the professional removalists you need to ensure
              a smooth and hassle-free transition.
            </p>
            <p className="information__justify">
              With us, you benefit from experts who have the skills and
              experience to tackle the challenges that often arise during a
              move. Whether it’s navigating tight staircases or handling bulky
              furniture, their expertise is invaluable.
            </p>
            <p className="information__justify">
              Time is another major advantage when you choose Skilled Movers.
              Our trained team allows you to save precious hours, which you can
              use to settle into your new place or focus on other important
              tasks.
            </p>
            <p className="information__justify">
              Safety is also our priority. We are equipped with the right tools
              and techniques to protect your belongings and ensure no one gets
              injured during the move.
            </p>
            <p className="information__justify">
              With Skilled Movers, you can enjoy peace of mind, knowing that
              everything is handled by experienced professionals. You can focus
              on starting fresh in Clayton without worrying about the details of
              the move.
            </p>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-6 skilled__movers__images">
            <img src={movers} alt="movers" className="img-fluid mover_image" />
          </div>
        </div>
        <div className="row ">
          <h2 className="text-center mb-5 pt-4 mt-lg-0">
            What you get when you book a Clayton removalist through Skilled
            Movers ?
          </h2>

          <div className="col-md-4">
            <div className="removalist-card shadow">
              <img
                src={truck}
                alt="Skilled-Clayton-Removalists"
                className="removalist__icons"
              />
              <h3>Skilled Clayton Removalists</h3>
              <p className="information__justify">
                A professional team with a removalist truck, tailored to the
                size of your move, will handle the collection and delivery of
                your belongings.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="removalist-card shadow">
              <img
                src={booking}
                alt="Convenient-Online-Booking"
                className="removalist__icons"
              />
              <h3>Convenient Online Booking</h3>
              <p className="information__justify">
                Skip the hassle of calling around for quotes. You can easily
                find, compare, and book your Clayton removalist online in just a
                few clicks.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="removalist-card shadow">
              <img
                src={personalized}
                alt="Personalized-Service"
                className="removalist__icons"
              />
              <h3>Personalized Service</h3>
              <p className="information__justify">
                Our knowledgeable customer service team, well-versed in
                Clayton's ins and outs, is here to assist you every step of the
                way.
              </p>
            </div>
          </div>
        </div>
        <div className="row pt-2 pt-lg-5">
          <div className="col-md-4">
            <div className="removalist-card shadow">
              <img
                src={life}
                alt="Protective-Coverage"
                className="removalist__icons"
              />
              <h3>Protective Coverage</h3>
              <p className="information__justify">
                Your items are safeguarded with heavy blankets, following best
                practices in the moving industry to prevent any damage during
                transit.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="removalist-card shadow">
              <img
                src={logistic}
                alt="Efficient-Loading-&-Unloading"
                className="removalist__icons"
              />
              <h3>Efficient Loading & Unloading</h3>
              <p className="information__justify">
                Every move includes complete loading and unloading services.
                Need help with packing? We offer packing services at an
                additional cost.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="removalist-card shadow">
              <img
                src={insurance}
                alt="Complimentary-Insurance"
                className="removalist__icons"
              />
              <h3>Complimentary Insurance</h3>
              <p className="information__justify">
                For added peace of mind, we provide complimentary insurance
                coverage on specified items.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="callback__section">
        <div className="container">
          <div className="row d-flex align-items-center callback__details">
            {/* Left Column - Image */}
            <div className="col-md-3">
              <img
                src={SitBack}
                alt="Sit-Back-and-Relax"
                className="img-fluid"
              />
            </div>

            {/* Middle Column - Text */}
            <div className="col-md-6 pt-2 p-lg-0">
              <h2 className="text-white">Sit Back & Relax</h2>
              <p className="text-white">
                We Pack, Move, Unpack, Clean and Utilities Disconnect &
                Re-Connect
              </p>
            </div>

            {/* Right Column - Button */}
            <div className="col-md-3 text-end callback__freequote">
              <a href="/" className="btn quote-button">
                Free Quote <span>&#8594;</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="container my-5">
        <div className="row d-flex justify-content-center align-items-center my-5">
          <div className="col-md-12 col-sm-12 col-lg-6 skilled__movers__images">
            <img
              src={removalists}
              alt="movers"
              className="img-fluid mover_image"
            />
          </div>
          <div className="col-md-12 col-sm-12 col-lg-6">
            <h2 className="text-center pt-3 p-lg-0">
              Types of services we offer as removalists in Clayton!
            </h2>
            <p className="information__justify">
              When searching for removalists in Clayton, it's essential to
              understand the variety of services they offer. Skilled Movers, one
              of the top removalist companies in Clayton, provides full-service
              moving options, including packing, loading, transporting, and
              unpacking your belongings.
            </p>
            <p className="information__justify">
              Some movers specialize in specific types of moves. For instance,
              if you're relocating a business or need furniture transport only,
              we offer tailored solutions to meet your specific needs.
            </p>
            <p className="information__justify">
              Storage services are provided by us. Whether you need short-term
              storage during a transition or long-term space for excess items,
              we can accommodate these needs.
            </p>
            <p className="information__justify">
              Additionally, we provide packing supplies like boxes and bubble
              wrap. We often offer expert advice on how to pack efficiently and
              safely.
            </p>
          </div>
        </div>
      </div>

      <section className="remove_moveus">
        <div className="container">
          <div className="row align-items-center">
            {/* Left Column - Blank or Other Content */}
            <div className="col-md-6 "></div>

            {/* Right Column*/}
            <div className="col-md-6 d-flex justify-content-center">
              <div className="removalists__ease__move  p-4">
                <p className="small-text">We Remove The Headache From Moving</p>
                <h2>Move With Ease</h2>
                <p className="information__justify">
                  With our super simple service, we can take you from getting a
                  quote, right through to setting up your new home with all your
                  belongings securely packed and safely delivered, even to the
                  room of your choice.
                </p>
                <a href="/" className="btn quote-button">
                  Get a Free Quote Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container my-5">
        <div className="row d-flex justify-content-center align-items-center my-5">
          <div className="col-md-12 col-sm-12 col-lg-6">
            <h2 className="text-center">
              Furniture Removalists Clayton – Safe and Secure Relocations
            </h2>
            <p className="information__justify">
              For furniture removals in Clayton, trust us to handle your most
              treasured possessions with exceptional care. At Skilled Movers,
              our skilled furniture removalist team excels in both delicate and
              heavy lifting, ensuring each item is protected throughout the
              entire process. From grand pianos to antique furniture, we
              guarantee a seamless, damage-free relocation. When you choose our
              furniture removalists in Clayton, your belongings aren’t just
              moved—they’re meticulously safeguarded.
            </p>
            <p className="information__justify">
              Moving furniture requires precision and expertise, and that’s
              exactly what our furniture movers in Clayton provide. From the
              initial planning to the final placement of your furniture, our
              team is hands-on every step of the way. No item is too bulky or
              too small; whether it’s fragile glassware or full-size couches, we
              make sure everything arrives safely. With our customized approach,
              you can trust that your furniture moving experience will be
              tailored to your specific needs, preferences, and schedule.
            </p>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-6 skilled__movers__images">
            <img
              src={relocations}
              alt="movers"
              className="img-fluid mover_image"
            />
          </div>
        </div>
      </div>

      <div className="container my-0 my-lg-5">
        <div className="row  ">
          <div className="col-md-12 col-sm-12 col-lg-6 skilled__movers__images">
            <img
              src={choosing}
              alt="movers"
              className="img-fluid mover_image"
            />
          </div>
          <div className="col-md-12 col-sm-12 col-lg-6">
            <h2 className="text-center pt-3 p-lg-0">
              Factors to consider when choosing a removalist company in Clayton
            </h2>
            <p className="information__justify">
              When selecting a removalist company, several crucial factors come
              into play. Start by assessing their experience and reputation in
              the industry. A well-established company often has proven
              reliability.
            </p>
            <p className="information__justify">
              Next, transparency in pricing is essential. Look for detailed
              quotes that cover all potential costs, so there are no surprises
              on moving day.
            </p>
            <p className="information__justify">
              Consider their range of services too. Some companies offer packing
              assistance or storage solutions, which can simplify your move
              significantly.
            </p>
            <p className="information__justify">
              Insurance coverage should not be overlooked either; it protects
              your belongings during transit.
            </p>
          </div>
          <div className="col-md-12">
            <p className="information__justify">
              Check customer reviews and testimonials to gauge satisfaction
              levels from past clients. This feedback will give you insight into
              how they handle challenges and customer service aspects throughout
              the moving process.
            </p>
            <p className="information__justify">
              Choose Skilled Movers as your removalists in Clayton for a
              seamless and stress-free moving experience. With years of industry
              expertise and a strong reputation for reliability, Skilled Movers
              ensures your belongings are handled with the utmost care. Our
              transparent pricing, comprehensive range of services, including
              packing and storage solutions, and robust insurance coverage
              provide peace of mind every step of the way. Trust Skilled Movers
              for exceptional customer service and a smooth transition to your
              new home in Clayton.
            </p>
          </div>
        </div>
      </div>

      <div className="container my-0 my-lg-5">
        <div className="row ">
          <div className="col-md-12 col-sm-12 col-lg-6">
            <h2 className="text-center">
              Tips for packing and preparing for the move!
            </h2>
            <p className="information__justify">
              Packing for a move can feel overwhelming, but with some strategic
              planning, it doesn’t have to be. Start early and give yourself
              plenty of time to tackle each room.
            </p>
            <p className="information__justify">
              Begin by decluttering your belongings. Sort items into keep,
              donate, and discard piles. This not only lightens your load but
              also simplifies the packing process.
            </p>
            <p className="information__justify">
              Use quality packing materials like sturdy boxes and bubble wrap.
              Label each box clearly with its contents and destination room in
              your new home. This will make unpacking smoother.
            </p>
            <p className="information__justify">
              Protect fragile items by wrapping them individually. Fill empty
              spaces in boxes with soft materials to prevent shifting during
              transit.
            </p>
          </div>

          <div className="col-md-12 col-sm-12 col-lg-6 skilled__movers__images">
            <img
              src={preparing}
              alt="movers"
              className="img-fluid mover_image"
            />
          </div>
          <div className="col-md-12 pt-3 p-lg-0">
            <p className="information__justify">
              Don’t forget the essentials! Pack a separate bag or box for
              necessities you’ll need immediately after arriving at your new
              place—think toiletries, clothes, and important documents.
            </p>
            <p className="information__justify">
              Enlist help from friends or family if possible; moving is always
              easier when shared!{" "}
            </p>
            <p className="information__justify">
              When preparing for your move, Skilled Movers, your trusted
              removalists in Clayton, recommend starting early to avoid
              last-minute stress. Begin by decluttering and sorting your
              belongings, packing items room by room, and labeling each box
              clearly. Use high-quality packing materials to protect fragile
              items, and consider packing an essentials box with items you'll
              need immediately upon arrival. Skilled Movers also offers
              professional packing services to ensure everything is securely
              packed and ready for transit, making your move as smooth and
              efficient as possible.
            </p>
          </div>
        </div>
      </div>
      <Claytoninfo />
    </>
  );
};

export default Clayton;
