import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import "../Home/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import HomePage from "./HomePage";
import Testimonial from "../Testimonial/Testimonial";
import IncrementNumber from "../IncrementNumber/IncrementNumber";
import { useNavigate } from "react-router-dom";
import bgImage1 from "../../Assests/image_home.webp";
import bgImage2 from "../../Assests/image_homea.webp";
import bgImage3 from "../../Assests/image_homeb.webp";
import bgImagea from "../../Assests/bgImagea.webp";
import banner from "../../Assests/banner.webp";
import { Helmet } from "react-helmet-async";

const images = [banner];
const googleMapsApiKey = "AIzaSyAtUa8t-LE_sdMtpNgvfBSbfE2Sil2cVvw";

export default function MovingForm() {
  const [currentImage, setCurrentImage] = useState(0);
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
      script.async = true;
      // script.onload = () => {
      //   if (window.google && window.google.maps) {
      //     new window.google.maps.places.Autocomplete(pickupRef.current, {
      //       types: ["geocode"],
      //     });
      //     new window.google.maps.places.Autocomplete(dropoffRef.current, {
      //       types: ["geocode"],
      //     });
      //   }
      // };

      script.onload = () => {
        if (window.google && window.google.maps) {
          const options = {
            types: ["geocode"],
            componentRestrictions: { country: "AU" },
          };

          new window.google.maps.places.Autocomplete(
            pickupRef.current,
            options
          );
          new window.google.maps.places.Autocomplete(
            dropoffRef.current,
            options
          );
        }
      };
      script.onerror = () => {
        console.error("Error loading Google Maps script");
      };
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();
  }, []);

  const handleQuoteRequest = useCallback(async () => {
    const fromLocation = pickupRef.current?.value || "";
    const toLocation = dropoffRef.current?.value || "";

    // Validation check
    if (!fromLocation || !toLocation) {
      setErrorMessage("Please provide the locations");
      return;
    }

    // Check if both locations are in Australia
    const isValidAUAddress = (location) => {
      return location.includes("Australia") || location.includes("AU");
    };

    if (!isValidAUAddress(fromLocation) || !isValidAUAddress(toLocation)) {
      setErrorMessage("Please provide valid Australian locations");
      return;
    }

    try {
      const response = await fetch(
        "https://booking.skilledmovers.com.au/api/new-enquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fromlocation: fromLocation,
            tolocation: toLocation,
          }),
        }
      );

      const data = await response.json();
      console.log("Quote data:", data);

      if (data.success) {
        // Save data to local storage
        localStorage.setItem("enquiry_id", data.enquiry_id);
        localStorage.setItem("from_location", fromLocation);
        localStorage.setItem("to_location", toLocation);

        console.log(
          "Enquiry ID, from location, and to location saved to local storage"
        );
        navigate("/accessproperties");
        window.location.reload();
      } else {
        console.error("Failed to save enquiry:", data.message);
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []);

  return (
    <>
      {/* Add meta title and description */}
      <Helmet>
        <title>Removalists Melbourne | Melbourne City Movers </title>
        <meta
          name="description"
          content="Our expert Melbourne movers will handle everything for you, from packing, unpacking, and residential to commercial removal, secure storage, and even setting up your new house."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {/* end of meta title */}

      {/* <div className="carousel-container">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-slide ${
              index === currentImage ? "active" : ""
            }`}
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
        <div className="moving-form" style={{marginLeft:"38%"}}>
          <h1 class="small-heading fs-4 font-weight-bold">Start your better move.</h1>

          <div className="form-group-container">
            <div
              className="form-group mb-0"
              style={{ justifyContent: "center" }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              <input
                style={{
                  width: "calc(100% - 40px)", 
                  padding: "15px 15px 15px 40px", 
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxSizing: "border-box", 
                  fontSize: "1rem",
                }}
                type="text"
                placeholder="Picking up from"
                ref={pickupRef}
              />
            </div>
            <div
              className="form-group mb-0"
              style={{ justifyContent: "center" }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              <input
                style={{
                  width: "calc(100% - 40px)", 
                  padding: "15px 15px 15px 40px", 
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxSizing: "border-box", 
                  fontSize: "1rem",
                }}
                type="text"
                placeholder="Dropping off at"
                ref={dropoffRef}
              />
            </div>
            {errorMessage && (
              <p
                style={{ color: "red", textAlign: "center", marginTop: "1rem" }}
              >
                {errorMessage}
              </p>
            )}
            <Link
              to="/accessproperties"
              className="quote-button"
              onClick={(e) => {
                e.preventDefault();
                handleQuoteRequest();
              }}
            >
              Get an Instant Quote
            </Link>
          </div>
        </div>
      </div> */}
      <div className="banner__image mobile__banner__image">
        <div className="banner skilled__content">
          <div className="text-section mobile__text">
            <h1 className="melbourn__mobile__heading">
              Your Trusted Melbourne Removalists <br />
            </h1>
            <h2 className="melbourn__mobile__second_heading_">
              Compare, Choose, Move
            </h2>
            {/* <p className="m-1">
              One Of Australia’s Top Rated Moving & Cleaning Company.
            </p>
            <p className="m-1 rating-text">
              4.9 <span className="gold-stars">★★★★★</span>
            </p>
            <p className="m-1">
              "Earned 248 Google Review from Satisfied Customers"
            </p> */}
          </div>
          <div className="form-section mobile__location w-50">
            <div className="moving-form">
              <h1 class="small-heading fs-4 font-weight-bold">
                Start your better move.
              </h1>

              <div className="form-group-container ">
                <div
                  className="form-group mb-0"
                  style={{ justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  <input
                    style={{
                      width: "calc(100% - 40px)",
                      padding: "15px 15px 15px 40px",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                      fontSize: "1rem",
                    }}
                    type="text"
                    placeholder="Picking up from"
                    ref={pickupRef}
                    onChange={() => setErrorMessage("")}
                  />
                </div>
                <div
                  className="form-group mb-0"
                  style={{ justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  <input
                    style={{
                      width: "calc(100% - 40px)",
                      padding: "15px 15px 15px 40px",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                      fontSize: "1rem",
                    }}
                    type="text"
                    placeholder="Dropping off at"
                    ref={dropoffRef}
                    onChange={() => setErrorMessage("")}
                  />
                </div>
                {errorMessage && (
                  <p
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
                <Link
                  to="/accessproperties"
                  className="quote-button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleQuoteRequest();
                  }}
                >
                  Get an Instant Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomePage />
      {/* <IncrementNumber /> */}
      <Testimonial />
    </>
  );
}
